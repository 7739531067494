import React, { useState } from "react";
import { Box, Typography as Label, Button as MyButton } from "@mui/material";
import { Colors } from "styles/theme/color";
import { Button, Container, Typography } from "components/elements";
import useResponsive from "utils/use-media-query";
import IllustrationNgringo from "assets/image/ngringo-illustration.png";
import Desa from "assets/image/desa.jpeg";
import PeopleIcon from "assets/logo/people.png";
import VillageIcon from "assets/logo/village.png";
import LocationIcon from "assets/logo/location.png";
import { v4 as uuidv4 } from "uuid";
import Item from "components/pages/dashboard/itembox";
import Floating from "components/pages/dashboard/floating";
import Members from "components/pages/dashboard/members";
import News from "components/pages/product/news";
import FAQ from "components/pages/product/faq";
import { useNavigate } from "react-router-dom";
import ServiceButton from "components/pages/product/customer-service";

const Headline = () => {
  const { tablet, laptop, laptop2 } = useResponsive();

  const navigate = useNavigate();

  // Initialize State
  const [isDetail, setIsDetail] = useState(false);

  const data = [
    {
      src: PeopleIcon,
      title: "Jumlah Penduduk",
      desc: "28.100 Jiwa",
    },
    {
      src: VillageIcon,
      title: `Luas Wilayah`,
      desc: "420.266 ha",
    },
    {
      src: LocationIcon,
      title: "Lokasi Desa",
      desc: "Karanganyar, Jateng",
    },
  ];

  const handleContent = () => {
    if (isDetail) {
      return (
        <>
          Desa Ngringo merupakan salah satu desa di Kecamatan Jaten, Kabupaten
          Karanganyar, Provinsi Jawa Tengah yang memiliki potensi UMKM yang
          sangat besar. Data yang dirilis oleh Dinas Perindustrian, Perdagangan,
          Koperasi, Usaha Kecil, dan Menengah Kabupaten Karanganyar tahun 2023
          memaparkan bahwa terdapat 349 UMKM yang aktif pada berbagai bidang dan
          tersebar di seluruh wilayah yang menjadikan Desa Ngringo memiliki UMKM
          paling banyak di Kecamatan Jaten. Tercatat ada 71 UMKM kuliner, 45
          UMKM toko kelontong, 35 UMKM jasa, 20 UMKM pertanian, 5 UMKM
          peternakan, dan 173 UMKM yang bergerak pada berbagai macam bidang pada
          tahun 2023.
          <br />
          <br />
          Berdasarkan potensi dan permasalahan yang dihadapi oleh UMKM, tim PPK
          Ormawa BEM FP UNS 2024, bersama masyarakat, pemerintah desa, mitra,
          dan pemangku kepentingan, berinisiatif untuk melaksanakan kegiatan
          pemberdayaan UMKM dengan model pembentukan wadah bagi seluruh UMKM di
          Desa Ngringo agar dapat berkembang. Wadah ini dinamakan “Rumah Bisnis
          Mandiri.” Wadah tersebut dirancang sebagai pusat pelatihan,
          pendampingan, mentoring, konsultasi, dan pemasaran produk UMKM. Pada
          tahun pertama, pengembangan UMKM difokuskan pada sektor kuliner,
          mengingat mayoritas usaha di desa ini bergerak di bidang tersebut.
          Selain itu, luasnya pangsa pasar, kemampuan adaptasi terhadap tren,
          serta pesatnya inovasi dalam sektor kuliner menjadi pertimbangan utama
          dalam pemilihan UMKM yang akan dikembangkan.
        </>
      );
    }

    return (
      <>
        Desa Ngringo merupakan salah satu desa di Kecamatan Jaten, Kabupaten
        Karanganyar, Provinsi Jawa Tengah yang memiliki potensi UMKM yang sangat
        besar. Data yang dirilis oleh Dinas Perindustrian, Perdagangan,
        Koperasi, Usaha Kecil, dan Menengah Kabupaten Karanganyar tahun 2023
        memaparkan bahwa terdapat 349 UMKM yang aktif pada berbagai bidang dan
        tersebar di seluruh wilayah yang menjadikan Desa Ngringo memiliki UMKM
        paling banyak di Kecamatan Jaten. Tercatat ada 71 UMKM kuliner, 45 UMKM
        toko kelontong, 35 UMKM jasa, 20 UMKM pertanian, 5 UMKM peternakan, dan
        173 UMKM yang bergerak pada berbagai macam bidang pada tahun 2023.
      </>
    );
  };

  return (
    <Box>
      <ServiceButton />
      <Container padding={laptop ? "120px 88px 0px" : "148px 24px 0px"}>
        <Box
          display={laptop ? "flex" : "grid"}
          justifyContent="space-between"
          padding={laptop ? "0px 32px" : "0px"}
        >
          <Box width={laptop ? "50%" : "100%"}>
            <Typography
              type={laptop ? "heading1" : "heading3"}
              textAlign={laptop ? "left" : "center"}
              lineHeight={laptop ? "130%" : "100%"}
              color={Colors.textColorPrimary}
            >
              RUMAH BISNIS MANDIRI Bersama Desa Ngringo
            </Typography>

            <Typography
              type={laptop ? "body2" : "body4"}
              lineHeight="150%"
              textAlign={laptop ? "left" : "center"}
              marginBottom={laptop ? "32px" : "48px"}
              marginTop={laptop ? "24px" : "16px"}
              secondary
            >
              Rumah Bisnis Mandiri bangga bekerja sama dengan Desa Ngringo untuk
              meningkatkan kesejahteraan masyarakat melalui pemanfaatan UMKM
              lokal. Bersama, kami menciptakan wadah inovatif yang mendukung
              pengembangan usaha kecil dan menengah desa, memberikan peluang
              baru untuk menambah penghasilan dan membangun ekonomi desa yang
              mandiri dan berkelanjutan.
            </Typography>

            <Box textAlign={laptop ? "start" : "center"}>
              <Button
                label="Lihat Toko"
                height="48px"
                width="170px"
                borderRadius="24px"
                onClick={() => navigate("/produk-umkm")}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            width={laptop ? "40%" : "100%"}
            marginTop={laptop ? "24px" : "32px"}
            justifyContent="center"
          >
            <img
              alt="headline-desa-ngringo"
              src={IllustrationNgringo}
              width={laptop2 ? "480px" : "320px"}
              height={laptop2 ? "360px" : "240px"}
              style={{ borderRadius: "15px" }}
            />
          </Box>
        </Box>

        {/* Item section */}
        <Box
          marginTop="84px"
          display={tablet ? "flex" : "grid"}
          justifyContent={tablet ? "space-between" : "center"}
          paddingX={laptop ? "100px" : "0px"}
          gap="12px"
        >
          {data.map((e) => (
            <React.Fragment key={uuidv4()}>
              <Item data={e} />
            </React.Fragment>
          ))}
        </Box>

        {/* About Ngringo */}
        <Box
          display={isDetail || !laptop ? "grid" : "flex"}
          justifyContent="space-between"
          marginTop={tablet ? "180px" : "80px"}
          padding={tablet ? "0px 32px" : "0px"}
          sx={{
            transition: "0.5s all ease",
          }}
        >
          <Box
            width={isDetail || !laptop ? "100%" : "40%"}
            display="flex"
            justifyContent="center"
          >
            <img
              alt="desa-ngringo"
              src={Desa}
              width={laptop2 ? "480px" : "320px"}
              height={laptop2 ? "360px" : "240px"}
              style={{ borderRadius: "15px" }}
            />
          </Box>

          <Box
            width={isDetail || !laptop ? "100%" : "55%"}
            marginTop={isDetail || !laptop ? "32px" : "0px"}
            paddingX={laptop ? "24px" : "0px"}
          >
            <Typography
              type={laptop ? "heading1" : "heading3"}
              textAlign={isDetail || !laptop ? "center" : "left"}
              lineHeight="130%"
              color={Colors.textColorPrimary}
            >
              Tentang Desa Ngringo
            </Typography>

            <Typography
              type={laptop ? "body2" : "body4"}
              lineHeight="150%"
              textAlign={laptop ? "left" : "center"}
              marginBottom={laptop ? "64px" : "32px"}
              marginTop={laptop ? "24px" : "16px"}
              secondary
            >
              {handleContent()}
              <span
                onClick={() => {
                  setIsDetail(!isDetail);
                }}
                style={{ color: Colors.primaryColorBlue100, cursor: "pointer" }}
              >
                {" "}
                {isDetail ? "Baca lebih sedikit." : "...Baca Selengkapnya"}
              </span>
            </Typography>
          </Box>
        </Box>

        {/* Our Team */}
        <Box marginY="100px">
          <Members />
        </Box>
      </Container>

      {/* News */}
      <Box id="joinnow" marginTop="48px">
        <News
          title="Ingin bergabung memajukan UMKM Desa Ngringo?"
          label="Daftar Sekarang"
        />
      </Box>

      {/* FAQ */}
      <Box display="flex" justifyContent="center">
        <FAQ
          data={[
            {
              question: "Apa itu RUMAH BISNIS MANDIRI?",
              answer:
                "Rumah Bisnis Mandiri merupakan wadah yang dirancang sebagai pusat pelatihan, pendampingan, mentoring, konsultasi, dan pemasaran produk UMKM di Desa Ngringo.",
            },
            {
              question: "Produk apa saja yang ditawarkan RUMAH BISNIS MANDIRI?",
              answer:
                "Minuman Herbal, Pempek, Tongseng Genthong, Nasi Bowl, Juice & Capuccino, Intip Goreng, Peyek Mbah Uti, Es Durian Kocok, Tape Ketan, Pisang & Palawija, Rica-rica Menthok & Soto, Kacang Telur, Bakery, Keripik Kulit Kebab ",
            },
            {
              question: "Bagaimana cara bergabung dengan RUMAH BISNIS MANDIRI?",
              answer:
                "Klik daftar dan isi form yang diberikan, tunggu maksimal 2 x 24 jam untuk mendapatkan verifikasi dan kelanjutan pendaftaran lebih lanjut dari kami.",
            },
          ]}
        />
      </Box>

      {/* Floating Section */}
      <Box padding={laptop ? "0px 88px 0px" : "48px 24px 0px"}>
        <Typography
          type="heading2"
          textAlign="center"
          lineHeight="130%"
          color={Colors.textColorPrimary}
        >
          Galeries
        </Typography>
        <Floating />
      </Box>

      {/* Instagram redirect */}
      <Box paddingX={laptop ? "148px" : "24px"} marginY="100px">
        <Box
          backgroundColor={Colors.textColorPrimary}
          padding="32px 48px"
          borderRadius="20px"
          display={laptop ? "flex" : "grid"}
          alignItems="center"
          justifyContent="center"
        >
          <Box width={laptop2 ? "659px" : "100%"}>
            <Typography
              type={laptop ? "heading5" : "heading5"}
              color={Colors.white}
            >
              Get to know more about us together to improve UMKM business
            </Typography>
          </Box>

          <MyButton
            sx={{
              marginTop: laptop ? "0px" : "12px",
              background: Colors.textColorPrimary,
              fontFamily: "Anek Bangla",
              color: Colors.white,
              textTransform: "none",
              fontWeight: 700,
              fontSize: laptop ? "18px" : "18px",
              border: `1px solid ${Colors.white}`,
              borderRadius: "30px",
              padding: tablet ? "9px 48px" : "10px",
              transition: "0.5 all ease",
              "&:hover": {
                border: `1px solid ${Colors.primaryOrange100}`,
                color: Colors.primaryOrange100,
              },
            }}
            onClick={() =>
              window.open(
                "https://www.instagram.com/rbm.official.store",
                "_blank"
              )
            }
          >
            Start your Journey
          </MyButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Headline;
