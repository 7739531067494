import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";

const useResponsive = () => {
  const theme = useTheme();
  const breakpoints = theme.breakpoints.values;

  const initializeViewport = () => {
    return Object.keys(breakpoints).reduce((acc, breakpoint) => {
      acc[breakpoint] = null;
      return acc;
    }, {});
  };

  const [viewport, setViewport] = useState(initializeViewport);

  const calculateViewport = () => {
    return Object.keys(breakpoints).reduce((acc, breakpoint) => {
      acc[breakpoint] = window.matchMedia(
        `(min-width: ${breakpoints[breakpoint]}px)`
      ).matches;
      return acc;
    }, {});
  };

  useEffect(() => {
    const handleResize = () => {
      setViewport(calculateViewport());
    };

    if (typeof window !== "undefined") {
      setViewport(calculateViewport());

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [breakpoints]);

  return viewport;
};

export default useResponsive;
