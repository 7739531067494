import React from "react";
import { Button } from "@mui/material";
import styled from "@emotion/styled";
import { Colors } from "styles/theme/color";

const CustomButton = ({
  id,
  type = "primary",
  submit,
  label,
  height,
  width,
  fontSize = "18px",
  margin,
  padding,
  borderRadius,
  sx,
  onClick,
  disabled,
  startIcon,
  endIcon,
}) => {
  const Component = styled(Button)(() => ({
    transitionDuration: "0s",
    textTransform: "none",
    height,
    width,
    margin,
    padding,
    transition: "0.5s all ease",
    border: `1px solid ${
      type === "secondary" ? Colors.primaryDarkBlue : Colors.textColorPrimary
    }`,
    borderRadius: borderRadius || "20px",

    fontStyle: "normal",
    fontWeight: 700,
    fontSize,
    lineHeight: "100%",

    background: type === "primary" ? Colors.textColorPrimary : "transparent",
    color: type === "primary" ? Colors.white : Colors.primaryDarkBlue,
    ":hover": {
      border: `1px solid ${Colors.primaryOrange100}`,
      color: Colors.primaryOrange100,
      background: "transparent",
    },
    ":active": {
      background:
        type === "primary"
          ? Colors.primaryColorDarkBlue100
          : Colors.primaryColorBlue40,
    },
    ":disabled": {
      background:
        type === "text" ? "transparent" : Colors.primaryColorDarkBlue20,
      border: "0px",
      opacity: 0.8,
      color: type === "primary" ? Colors.white : Colors.primaryColorBlue100,
    },
  }));

  return (
    <Component
      id={id}
      type={submit && "submit"}
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={sx}
    >
      {label}
    </Component>
  );
};

export default CustomButton;
