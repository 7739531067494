import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { Colors } from "styles/theme/color";
import { useNavigate } from "react-router-dom";

const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ color, secondary }) =>
    color ||
    (secondary && Colors.textColorSecondary) ||
    Colors.textColorPrimary};
  cursor: pointer;
`;

const CustomLink = React.memo(
  ({ children, href, color, secondary, target, ariaLabel = "" }) => {
    const navigate = useNavigate();

    const handleClick = useCallback(
      (e) => {
        if (href.startsWith("#")) {
          e.preventDefault();
          const destination = document.querySelector(href);
          if (destination) {
            destination.scrollIntoView({
              behavior: "smooth",
            });
          }
        } else if (!href.startsWith("http")) {
          e.preventDefault();
          navigate(href);
        }
      },
      [href, navigate]
    );

    return (
      <StyledLink
        aria-label={ariaLabel}
        color={color}
        secondary={secondary}
        href={href}
        target={target}
        onClick={handleClick}
        onTouchStart={handleClick}
        onTouchMove={handleClick}
        onWheel={handleClick}
        onTouchEnd={handleClick}
      >
        {children}
      </StyledLink>
    );
  }
);

export default CustomLink;
