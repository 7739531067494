import React from "react";
import { Container } from "components/elements";
import styled from "@emotion/styled";
// import { Grid } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import useResponsive from "utils/use-media-query";
import Image1 from "assets/image/1.jpeg";
import Image2 from "assets/image/2.jpeg";
import Image3 from "assets/image/3.jpeg";
import Image4 from "assets/image/4.jpeg";
import Image5 from "assets/image/5.jpeg";
import Image6 from "assets/image/6.jpeg";
import Image7 from "assets/image/7.jpeg";
import Image8 from "assets/image/8.jpeg";

const FLoatingImage = styled.img(
  (height, width) => `
    cursor: pointer;  
    object-fit: cover;
    border-radius: 20px;
    width: ${width};
    height: ${height};
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    transition: 0.7s;
    &:hover {
      transform: scale(1.3);
      z-index: 2;
    }
  `
);

const Floating = () => {
  const { laptop, laptop2, tablet, mobile, mobile2 } = useResponsive();

  // Initial Size of Grid as a Variable
  let sizeX = laptop
    ? "300px"
    : laptop2
    ? "252px"
    : tablet
    ? "158px"
    : mobile
    ? "180px"
    : "157px";

  let sizeY = laptop
    ? "187px"
    : laptop2
    ? "150px"
    : tablet
    ? "100px"
    : mobile
    ? "110px"
    : "100px";

  return (
    <Container
      padding={
        laptop
          ? "0px 70px"
          : laptop2 | mobile
          ? "0px 24px"
          : tablet
          ? "0px 0px"
          : mobile2
          ? "0px 50px"
          : "0px 24px"
      }
      paddingtop="20px"
      paddingbottom="100px"
    >
      {tablet && (
        <Box position="absolute">
          <Grid container spacing={laptop ? 2 : 1} justifyContent="center">
            <Grid
              item
              style={{
                marginTop: laptop
                  ? "120px"
                  : laptop2
                  ? "105px"
                  : tablet
                  ? "55px"
                  : mobile
                  ? "0px"
                  : "0px",
              }}
            >
              <FLoatingImage
                src={Image1}
                alt="Floating Image 1"
                height={sizeY}
                width={sizeX}
              />
            </Grid>
            <Grid
              item
              style={{
                marginTop: laptop
                  ? "180px"
                  : laptop2
                  ? "165px"
                  : tablet
                  ? "90px"
                  : mobile
                  ? "25px"
                  : "25px",
              }}
            >
              <FLoatingImage
                src={Image2}
                alt="Floating Image 2"
                height={sizeY}
                width={sizeX}
              />
            </Grid>
            <Grid
              item
              style={{
                marginTop: laptop
                  ? "65px"
                  : laptop2
                  ? "65px"
                  : tablet
                  ? "30px"
                  : mobile
                  ? "0px"
                  : "8px",
              }}
            >
              <FLoatingImage
                src={Image3}
                alt="Floating Image 3"
                height={sizeX}
                width={sizeY}
              />
            </Grid>
            <Grid
              item
              style={{
                marginTop: laptop
                  ? "160px"
                  : laptop2
                  ? "145px"
                  : tablet
                  ? "70px"
                  : mobile
                  ? "5px"
                  : "5px",
              }}
            >
              <FLoatingImage
                src={Image4}
                alt="Floating Image 4"
                height={sizeY}
                width={sizeX}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <Box
        marginTop={
          laptop ? "300px" : laptop2 ? "240px" : tablet ? "115px" : "0px"
        }
      >
        <Grid container spacing={laptop ? 2 : 1} justifyContent="center">
          <Grid
            item
            style={{
              marginTop:
                laptop || laptop2
                  ? "25px"
                  : tablet
                  ? "50px"
                  : mobile
                  ? "15px"
                  : "30px",
              marginLeft: laptop || laptop2 ? "0px" : tablet ? "35px" : "0px",
            }}
          >
            <FLoatingImage
              src={Image5}
              alt="Floating Image 5"
              height={sizeX}
              width={sizeY}
            />
          </Grid>
          <Grid item style={{ marginTop: "85px" }}>
            <FLoatingImage
              src={Image6}
              alt="Floating Image 6"
              height={tablet ? sizeX : sizeY}
              width={tablet ? sizeY : sizeX}
            />
          </Grid>
          <Grid item style={{ marginTop: tablet ? "85px" : "0px" }}>
            <FLoatingImage
              src={Image7}
              alt="Floating Image 7"
              height={sizeY}
              width={sizeX}
            />
          </Grid>
          <Grid item style={{ marginTop: tablet ? "65px" : "0px" }}>
            <FLoatingImage
              src={Image8}
              alt="Floating Image 8"
              height={sizeX}
              width={sizeY}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Floating;
