import React from "react";
import styled from "@emotion/styled";
import { Box, Skeleton } from "@mui/material";
import { Colors } from "styles/theme/color";
import { Typography } from "components/elements";
import useResponsive from "utils/use-media-query";

const ItemBox = styled(Box)(
  ({ laptop2 }) => `
    width: ${laptop2 ? "255px" : "162px"};
    height: ${laptop2 ? "192px" : "162px"};
    background-color: ${Colors.grey};
    border-radius: 20px;
    overflow: hidden;
    position: relative;
  `
);

const Item = ({ data }) => {
  const { laptop2 } = useResponsive();

  if (!data)
    return (
      <Skeleton
        variant="rectangle"
        width="255px"
        height={laptop2 ? "192px" : "260px"}
        sx={{ borderRadius: "20px" }}
      />
    );
  return (
    <ItemBox laptop2={laptop2 ? 1 : 0}>
      <Box padding="16px">
        <img
          src={data.src}
          alt="item"
          width={laptop2 ? "48px" : "28px"}
          height={laptop2 ? "48px" : "28px"}
        />

        <Typography
          type={laptop2 ? "heading3" : "heading6"}
          marginTop={laptop2 ? "32px" : "12px"}
        >
          {data.title}
        </Typography>
        <Typography
          type={laptop2 ? "body2" : "body4"}
          marginTop={laptop2 ? "8px" : "4px"}
          secondary
        >
          <span
            style={{
              transition: "0.5s all ease",
              "&:hover": {
                color: Colors.primaryOrange100,
              },
            }}
          >
            {data.desc}
          </span>
        </Typography>
      </Box>
    </ItemBox>
  );
};

export default Item;
