import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import AllRoutes from "./routes";
import theme from "styles/theme";

function App() {
  return (
    <BrowserRouter basename="/">
      <ThemeProvider theme={theme}>
        <AllRoutes />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
