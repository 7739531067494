import React from "react";
import {
  Container,
  Typography,
  CarouselV2,
  Pagination,
} from "components/elements";
import MemberCard from "./member-card";
import useResponsive from "utils/use-media-query";
import Afifah from "assets/image/afifah.jpeg";
import Ahmad from "assets/image/ahmad.jpeg";
import Annisa from "assets/image/annisa.jpeg";
import Auliya from "assets/image/auliya.jpeg";
import Aviciena from "assets/image/aviciena.jpeg";
import Difa from "assets/image/difa.jpeg";
import Fajri from "assets/image/fajri.jpeg";
import Hilda from "assets/image/hilda.jpeg";
import Istiqomah from "assets/image/istiqomah.jpeg";
import MuhammadAji from "assets/image/muhammad-aji.jpeg";
import MuhammadAl from "assets/image/muhammad-al.jpeg";
import Savina from "assets/image/savina.jpeg";
import Shalsha from "assets/image/shalsha.jpeg";
import Siti from "assets/image/siti.jpeg";
import Yuris from "assets/image/yuris.jpeg";
import { Box } from "@mui/material";
import { Colors } from "styles/theme/color";

// Carousel responsive settings
const responsive = {
  laptop: {
    breakpoint: { max: 8000, min: 1280 },
    items: 4,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
  laptop2: {
    breakpoint: { max: 1279, min: 960 },
    items: 3,
    slidesToSlide: 1,
    partialVisibilityGutter: 50,
  },
  tablet: {
    breakpoint: { max: 959, min: 600 },
    items: 2,
    slidesToSlide: 1,
    partialVisibilityGutter: 20,
  },
  mobilexxl: {
    breakpoint: { max: 599, min: 570 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 180,
  },
  mobilexxl: {
    breakpoint: { max: 569, min: 530 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 140,
  },
  mobilexl: {
    breakpoint: { max: 529, min: 490 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 100,
  },
  mobilelg: {
    breakpoint: { max: 489, min: 450 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 60,
  },
  mobilemd: {
    breakpoint: { max: 449, min: 420 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 100,
  },
  mobilexs: {
    breakpoint: { max: 409, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 40,
  },
};

const Members = () => {
  const { laptop, tablet } = useResponsive();

  const data = [
    Ahmad,
    Afifah,
    Annisa,
    Auliya,
    Aviciena,
    Difa,
    Fajri,
    Hilda,
    Istiqomah,
    MuhammadAji,
    MuhammadAl,
    Savina,
    Shalsha,
    Siti,
    Yuris,
  ];

  const CustomButtonGroup = ({
    next,
    previous,
    goToSlide,
    carouselState,
    totalItems,
  }) => {
    const { currentSlide } = carouselState;

    let pageLimit =
      laptop && totalItems > 3 ? totalItems - 3 : !laptop ? totalItems : 1;

    return (
      <Box
        position="absolute"
        display="flex"
        justifyContent="center"
        width="100%"
        top={tablet ? "90%" : "87%"}
        marginTop="5px"
      >
        <Pagination
          currentPage={currentSlide + 1}
          pageLimit={pageLimit}
          handlePrev={() => previous()}
          handleNext={() => next()}
          handleToFirstPage={() => goToSlide(0)}
          handleToEndPage={() => goToSlide(totalItems - 4)}
          goToSlide={goToSlide}
          customFunction
        />
      </Box>
    );
  };

  return (
    <Container
      padding={laptop ? "100px 100px" : "0px 24px"}
      paddingtop={laptop ? "60px" : "0px"}
      paddingbottom="40px"
    >
      <Typography
        type="heading2"
        marginBottom="40px"
        textAlign="center"
        color={Colors.textColorPrimary}
      >
        Tim Kami
      </Typography>
      <CarouselV2
        data={data}
        responsive={responsive}
        ariaLabel="members-ekuid"
        component={MemberCard}
        customButtonGroup={<CustomButtonGroup totalItems={data.length} />}
      />
    </Container>
  );
};

export default Members;
