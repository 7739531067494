export const Colors = {
  // Monokrom
  white: "#FFFFFF",
  black: "#000000",
  background: "#E5E5E5",
  grey: "#F5F4F1",
  lightGrey: "#F8F8F8",
  darkGrey: "#C3C8D1",
  secondaryGrey: "#D0D8D8",
  transparent: "rgba(13, 13, 13, 0.4)",
  pale: "#FFFFFF4D",

  //Shadow
  shadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  shadowOrange: "#f8981d 0px 2px 8px 0px",
  shadowLight: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
  shadowLightBlue: "0px 20px 40px 0px rgba(48, 103, 154, 0.10)",
  shadowSketch:
    "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",

  // Gradient
  gradientColorBlue: "linear-gradient(270deg, #2b66d8, #255eca, #193f88 100%)",
  gradientColorBrown: "linear-gradient(270deg, #aea37e, #938552, #786728 100%)",
  gradientColorOrange:
    "linear-gradient(270deg, #f8c379, #f6af4c, #f49b20 100%)",
  gradientColorSilver:
    "linear-gradient(268deg, #DADADA 13.28%, #A6A6A6 113.13%)",
  gradientColorGold: "linear-gradient(113deg, #DFD2A5 -5.07%, #A39669 88.4%)",
  gradientColorPlatinum:
    "linear-gradient(228deg, #7C7D86 -1.55%, #4E4F5B 123.51%)",
  gradientColorDiamond:
    "linear-gradient(80deg, #138080 -1.95%, #4BB1B8 103.28%)",

  // Text Color
  textColorPrimary: "#193f88",
  textColorSecondary: "#79859D",
  textPrimary: "#243962",
  textSecondary: "#69758B",

  // Primary Color Blue
  primaryColorBlue100: "#3E95CC",
  primaryColorBlue80: "#65AAD6",
  primaryColorBlue60: "#8BBFE0",
  primaryColorBlue40: "#B2D5EB",
  primaryColorBlue20: "#D8EAF5",
  primaryBlue: "#005B9C",
  primaryBlue100: "#29406D",
  primaryBlue80: "#419CCF",
  primaryBlue60: "#0088e9",
  primaryBlue40: "#3079AF",
  primaryBlue20: "#CCDEEB",

  // Primary Color Dark Blue
  primaryColorDarkBlue100: "#1A5D9E",
  primaryColorDarkBlue80: "#487DB1",
  primaryColorDarkBlue60: "#769EC5",
  primaryColorDarkBlue40: "#A3BED8",
  primaryColorDarkBlue20: "#D1DFEC",
  primaryDarkBlue: "#004A7F",

  // Primary Color Light Blue
  primaryColorLightBlue100: "#3186FF",
  primaryColorLightBlue80: "#5A9EFF",
  primaryColorLightBlue60: "#83B6FF",
  primaryColorLightBlue40: "#ADCFFF",
  primaryColorLightBlue20: "#D6E7FF",
  primaryLightBlue: "#F2F7FA",
  primaryLightBlue80: "#F7FBFB",
  primaryLightBlue60: "#F3F7FF",
  primaryLightBlue40: "#D1E0FE",
  primaryLightBlue20: "#F2F7FA",

  //Primary Color Orange
  primaryOrange100: "#f8981d",
  primaryOrange80: "#f49b20",

  //Primary Color Brown
  primaryBrown100: "#786728",
  primaryBrown80: "#79652d",

  // Azure
  secondaryColorAzure100: "#5D8AFF",
  secondaryColorAzure80: "#38C8F5",

  // Yellow
  secondaryColorYellow100: "#F5AA38",
  secondaryColorYellow80: "#F7BB60",
  secondaryColorYellow60: "#F9CC88",
  secondaryColorYellow40: "#FBDDAF",
  secondaryColorYellow20: "#FDEED7",
  secondaryYellow100: "#F88419",
  secondaryYellow20: "#FFEDDD",

  // Light Yellow
  secondaryLightYellow: "#F884190D",

  // Purple
  secondaryColorPurple100: "#A994EC",
  secondaryColorPurple80: "#BAA9F0",
  secondaryColorPurple60: "#CBBFF4",
  secondaryColorPurple40: "#DDD4F7",
  secondaryColorPurple20: "#EEEAFB",
  secondaryPurple20: "#F3F7FF",

  // Green
  secondaryColorGreen100: "#379B45",
  secondaryColorGreen80: "#5FAF6A",
  secondaryColorGreen60: "#87C38F",
  secondaryColorGreen40: "#AFD7B5",
  secondaryColorGreen20: "#D7EBDA",
  secondaryColorBrand: "#62DCB2",
  secondaryGreen: "#43CE2C",
  secondaryGreen40: "#DDFFED",
  secondaryGreen20: "#F5FCF4",

  // Light Green
  secondaryColorLightGreen100: "#2AD8B8",
  secondaryLightGreen20: "#F7FBFB",
  secondaryLightGreen: "#F5FCF4",

  // Red
  secondaryColorRed100: "#FF5D62",
  secondaryColorRed80: "#FF7D81",
  secondaryColorRed60: "#FF9EA1",
  secondaryColorRed40: "#FF9EA1",
  secondaryColorRed20: "#FFDFE0",
  secondaryRed100: "#F56464",
  secondaryRed20: "#F9C9C9",

  // Light Red
  secondaryLightRed: "#F564640D",

  // Dark Red
  secondaryColorDarkRed100: "#D33930",

  // Silver
  secondaryColorSilver100: "#A8A8A8",
  secondaryColorSilver20: "#EEE",

  // Gold
  secondaryColorGold100: "#A0925D",
  secondaryColorGold20: "#FDF5D7",

  // Platinum
  secondaryColorPlatinum100: "#9999A7",
  secondaryColorPlatinum20: "#4D4E57",

  // Diamond
  secondaryColorDiamond100: "#137578",
  secondaryColorDiamond20: "#4AAFB6",
};
