import React from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { Colors } from "styles/theme/color";
import loadingIcon from "assets/logo/loading-icon.png";

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Loader = styled.img`
  animation: ${spin} 0.6s linear infinite;
`;

const Wrapper = styled(Box)`
  background-color: ${Colors.white};
  width: 52px;
  height: 52px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Background = styled(Box)`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(13, 13, 13, 0.4);
`;

const Loading = () => {
  return (
    <Background>
      <Wrapper>
        <Loader src={loadingIcon} alt="loading" width="48px" height="48px" />
      </Wrapper>
    </Background>
  );
};

export default Loading;
