import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import useResponsive from "utils/use-media-query";
import { Colors } from "styles/theme/color";
import { Button, Container } from "components/elements";
import { Box, Typography, TextField } from "@mui/material";
import Loading from "components/layout/loading";
import { phoneNumber } from "utils/form-validation";
import { getLocalStorage } from "utils/local-storage";

const Component = styled(Box)(
  ({ padding }) => `
    background-color: ${Colors.white};
    box-shadow: 0px 10px 40px rgba(164, 149, 107, 0.1);
    padding: ${padding};
    border-radius: 20px;
    outline: none;
    box-sizing: border-box; /* Ensure proper sizing */
  `
);

const OwnerCreation = () => {
  const { id } = useParams();
  const { laptop } = useResponsive();
  const navigate = useNavigate();
  const access_token = getLocalStorage("access_token");

  // State to capture form input
  const [payload, setPayload] = useState({
    owner: "",
    phone_number: "",
    instagram: "",
    shopee: "",
    address: "",
    tokopedia: "",
    optional: "",
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Handle form input change
  const handleChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleCreate = async (e) => {
    e.preventDefault();
    const errorNumber = phoneNumber(payload.phone_number);
    if (errorNumber) {
      setError(errorNumber);
      return;
    }

    setLoading(true);
    try {
      const url = id ? `/update/${id}` : "/create";

      const { data } = await axios.post(
        "https://umkm-server.onrender.com/owner" + url,
        { ...payload, phone_number: "+62" + payload.phone_number },
        { headers: { access_token: JSON.parse(access_token) } }
      );
      setLoading(false);
      navigate("/backoffice-owner");
    } catch (e) {
      setLoading(false);
      setError(e.response?.data?.message || "Error creating owner");
    }
  };

  const getDataDetail = async () => {
    try {
      const { data } = await axios.get(
        `https://umkm-server.onrender.com/owner/${id}`
      );
      setPayload({ ...data, phone_number: data.phone_number?.substring(3) });
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    if (!access_token) navigate("/produk-umkm");
  }, []);

  useEffect(() => {
    if (id) getDataDetail();
  }, [id]);

  if (loading) return <Loading />;

  return (
    <Container
      style={{ width: "100%", boxSizing: "border-box" }}
      padding={laptop ? "100px 120px 64px" : "128px 24px 0px"}
    >
      <Box
        display="flex"
        justifyContent="end"
        marginBottom="18px"
        onClick={() => navigate(-1)}
      >
        <Button
          label="⇚ Kembali"
          height="48px"
          width="150px"
          borderRadius="8px"
          type="secondary"
          submit
        />
      </Box>
      <Component padding="32px 63px">
        <Typography variant="h1" fontSize={24} fontWeight={700}>
          {id ? "Perbaharui Pemilik" : "Tambahkan Pemilik"}
        </Typography>

        <form onSubmit={handleCreate}>
          <Box display="flex" justifyContent="space-between" marginTop="32px">
            <Box width="45%">
              <TextField
                name="owner"
                type="text"
                placeholder="Es Ronaldo"
                label="Nama"
                sx={{ marginBottom: "24px" }}
                required
                fullWidth
                value={payload.owner}
                onChange={handleChange}
              />
              <TextField
                name="phone_number"
                type="text"
                placeholder="82266786389"
                label="WhatsApp"
                sx={{ marginBottom: "24px" }}
                required
                fullWidth
                value={payload.phone_number}
                onChange={handleChange}
              />
              <TextField
                name="instagram"
                type="text"
                placeholder="esbuah_semarang"
                label="Instagram"
                sx={{ marginBottom: "24px" }}
                fullWidth
                value={payload.instagram}
                onChange={handleChange}
              />
              <TextField
                name="shopee"
                type="text"
                placeholder="knowledgezenith"
                label="Shopee"
                sx={{ marginBottom: "24px" }}
                fullWidth
                value={payload.shopee}
                onChange={handleChange}
              />
            </Box>
            <Box width="45%">
              <TextField
                name="address"
                type="text"
                placeholder="Jl. Imam Bonjol No.207, Pendrikan Kidul, Kec. Semarang Tengah, Kota Semarang,"
                label="Alamat"
                sx={{ marginBottom: "24px" }}
                required
                fullWidth
                value={payload.address}
                onChange={handleChange}
              />
              <TextField
                name="tokopedia"
                type="text"
                placeholder="pediabakso"
                label="Tokopedia"
                sx={{ marginBottom: "24px" }}
                fullWidth
                value={payload.tokopedia}
                onChange={handleChange}
              />
              <TextField
                name="optional"
                type="text"
                placeholder="https://youtube.com"
                label="Website"
                sx={{ marginBottom: "24px" }}
                fullWidth
                value={payload.optional}
                onChange={handleChange}
              />
            </Box>
          </Box>

          <Box
            marginY="24px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="12px"
          >
            <Button
              label="Batal"
              height="48px"
              width="170px"
              borderRadius="24px"
              type="button"
              onClick={() =>
                setPayload({
                  owner: "",
                  phone_number: "",
                  instagram: "",
                  shopee: "",
                  address: "",
                  tokopedia: "",
                  optional: "",
                })
              }
            />
            <Button
              label="Submit"
              height="48px"
              width="170px"
              borderRadius="24px"
              submit
            />
          </Box>
        </form>

        {error && (
          <Typography
            color="error"
            variant="body2"
            marginTop="16px"
            textAlign="center"
          >
            {error}
          </Typography>
        )}
      </Component>
    </Container>
  );
};

export default OwnerCreation;
