import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { Typography } from "components/elements";
import { Colors } from "styles/theme/color";
import useResponsive from "utils/use-media-query";

const members = [
  {
    name: "Ahmad Hafidz Aditya Jati",
    major: "Agroteknologi",
    testimony:
      "Produk UMKM selalu beragam, unik, dan berpotensi untuk mencapai pasar yang lebih besar. Rumah Bisnis Mandiri hadir membersamai UMKM Desa Ngringo untuk mengusahakan hal itu. Ayo Beli, Konsumsi, dorong UMKM Berdikari!",
  },
  {
    name: "Afifah Salsabil Sholehah",
    major: "Agribisnis",
    testimony:
      "Program Rumah Bisnis Mandiri sangat membantu pelaku UMKM Desa Ngringo karena memberikan fasilitas berupa pelatihan, pendampingan, dan perluasan pemasaran, sehingga produk UMKM Desa Ngringo dapat dikenal masyarakat luas.",
  },
  {
    name: "Annisa Fitri Nur Salmi",
    major: "Penyuluhan dan Komunikasi Pertanian",
    testimony:
      "Ngringo sangat banyak produk-produk lokal yang berkualitas, dari Program Rumah Bisnis Mandiri ini menjadi salah satu bentuk branding dan pemasaran yang tepat bagi produk UMKM di Desa Ngringo",
  },
  {
    name: "Auliya Azizah",
    major: "Agroteknologi",
    testimony:
      "Rumah Bisnis Mandiri sangat solutif bagi masyarakat untuk mempermudah menemukan dan memasarkan produk UMKM di Desa Ngringo",
  },
  {
    name: "Aviciena Nailil Muna",
    major: "Agroteknologi",
    testimony:
      "produk yang ditawarkan oleh Rumah Bisnis Mandiri sangat bervariasi dan sangat berkualitas, produk yang ditawarkan sudah tersertifikasi halal dan juga pastinya sangat ramah dikantong",
  },
  {
    name: "Difa Elzitavira Fivtachrudin",
    major: "Ilmu Tanah",
    testimony:
      "Rumah Bisnis Mandiri berfokus pada pemberdayaan Usaha Mikro, Kecil, dan Menengah (UMKM) di Desa Ngringo, membantu meningkatkan kapasitas dan kemandirian para pengusaha lokal dengan mengembangkan berbagai produk unggulan",
  },
  {
    name: "Fajri Ramdhani",
    major: "Ilmu Tanah",
    testimony:
      "Produk unggulan di Desa Ngringo sangat beragam, salah satu upaya untuk meningkatkan branding yaitu dengan adanya Rumah Bisnis Mandiri yang inovatif, kreatif, dan solutif bagi penjualan produk di Desa Ngringo",
  },
  {
    name: "Hilda Marellia Putri Siswanto",
    major: "Kehutanan",
    testimony:
      "produk diversifikasi yang dipromosikan dan dijual di Rumah Bisnis Mandiri sangat inovatif. Tidak hanya murah tapi juga rasanya dan kualitasnya terjaga.",
  },
  {
    name: "Istiqomah",
    major: "Agroteknologi",
    testimony:
      "Aneka produk UMKM di Rumah Bisnis Mandiri sangat bervariasi serta harganya yang terjangkau, banyaknya pilihan produk makanan sehat sangat menjamin kualitasnya",
  },
  {
    name: "Muhammad Aji Pamungkas",
    major: "Agroteknologi",
    testimony:
      "Rumah Bisnis Mandiri adalah adalah salah satu solusi bagi UMKM di Desa Ngringo untuk memasarkan dan inovasi untuk mengenalkan produk-produk lokal Ngringo",
  },
  {
    name: "Muhammad Al Fatih",
    major: "Agroteknologi",
    testimony:
      "Rumah Bisnis Mandiri menawarkan produk-produk UMKM di Desa Ngringo yang beranekaragam dengan harga yang ramah dompet, dan pastinya sehat karena produknya berkualitas serta sudah bersertifikat halal",
  },
  {
    name: "Savina Puspa Dwiaji",
    major: "Agribisnis",
    testimony:
      "Rumah bisnis Mandiri ini mempermudah UMKM dalam memasarkan dan menjualkan produk-produk di Desa Ngringo",
  },
  {
    name: "Shalsha Putri Mayang Sari",
    major: "Agribisnis",
    testimony:
      "Program Rumah Bisnis Mandiri menjadi wadah dalam memasarkan dan menjual produk lokal UMKM di Desa Ngringo",
  },
  {
    name: "Siti Zulaikah",
    major: "Agroteknologi",
    testimony:
      "Produk-produk di Desa Ngringo sangat cocok untuk semua kalangan karena harga produk yang terjangkau, berkualitas, dan bersertifikasi PIRT dan halal, dengan adanya Program Rumah Bisnis Mandiri ini menjadi solusi untuk produk UMKM di Desa Ngringo ",
  },
  {
    name: "Yuris Gahara Aulia Hartanto",
    major: "Agribisnis",
    testimony:
      "Rumah Bisnis Mandiri menghadirkan berbagai macam jenis produk UMKM.  Harganya yang terjangkau, produk yang variatif, kemasan yang menarik, membuat produk di RBM sangat menarik untuk dibeli serta cocok untuk semua kalangan.",
  },
];

const ContainerCard = styled(Box)(({ transform }) => ({
  width: "100%",
  height: "100%",
  position: "relative",
  marginBottom: "48px",
  borderRadius: "40px",
  boxShadow: "0px 10px 40px rgba(164, 149, 107, 0.1)",
  WebkitTransformStyle: "preserve-3d",
  transformStyle: "preserve-3d",
  cursor: "pointer",
  textAlign: "left",
  transition: "transform 1.2s",
  WebkitTransform: transform,
  transform: transform,
}));

const MemberImage = styled.img`
  cursor: pointer;
  object-fit: cover;
  border-radius: 40px;
  width: 100%;
  height: 100%;
  user-drag: none;
  position: absolute;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
`;

const MemberCard = ({ data, index = 0 }) => {
  const { laptop, tablet, mobile } = useResponsive();
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [testimoni, setTestimoni] = useState("");
  const [transform, setTransform] = useState("");

  //Handle text on card
  useEffect(() => {
    setName(members[index].name);
    setPosition(members[index].major);
    setTestimoni(members[index].testimony);
  }, []);

  return (
    <Box
      width={laptop ? "255px" : mobile ? "210px" : "230px"}
      height={laptop ? "350px" : mobile ? "300px" : "320px"}
      marginBottom="58px"
      onClick={() =>
        transform ? setTransform("") : setTransform("rotateY(180deg)")
      }
    >
      <ContainerCard transform={transform}>
        {/* front card*/}
        <MemberImage src={data} alt="member-image" />

        {/* back card */}
        <Box
          padding="20px"
          borderRadius="40px"
          height="100%"
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          position="absolute"
          backgroundColor={Colors.white}
          style={{
            transform: "rotateY(180deg)",
            WebkitTransform: "rotateY(180deg)",
            WebkitBackfaceVisibility: "hidden",
            backfaceVisibility: "hidden",
          }}
        >
          <Box>
            <Typography type={laptop ? "body4" : "caption1"}>
              {testimoni}
            </Typography>
          </Box>
          <Box>
            <Typography type={laptop ? "heading3" : "heading3"}>
              {name}
            </Typography>
            <Typography type={tablet ? "body3" : "body3"} secondary>
              {position}
            </Typography>
          </Box>
        </Box>
      </ContainerCard>
    </Box>
  );
};

export default MemberCard;
