import React from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { Typography } from "components/elements";
import useResponsive from "utils/use-media-query";

const ComponentAccordion = ({
  id,
  title,
  detail,
  defaultExpanded = false,
  padding,
  marginBottom,
  onChange,
  borderBottom,
  backgroundColor,
  borderRadius = "40px !important",
}) => {
  const { laptop } = useResponsive();

  const summaryStyles = {
    padding: padding,
    "& .MuiAccordionSummary-content": { margin: 0 },
  };

  return (
    <Accordion
      onChange={onChange}
      expanded={defaultExpanded}
      disableGutters
      sx={{
        borderRadius: borderRadius,
        boxShadow: "none",
        padding: 0,
        borderBottom: borderBottom,
        backgroundColor: backgroundColor,
        marginBottom: marginBottom,
        "&::before": {
          backgroundColor: "transparent",
        },
      }}
    >
      <AccordionSummary
        id={id}
        aria-controls={`${id}-content`}
        sx={summaryStyles}
      >
        <Typography type="heading3" fontSize={laptop ? "24px" : "18px"}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: padding, paddingTop: 0 }}>
        {detail}
      </AccordionDetails>
    </Accordion>
  );
};

export default ComponentAccordion;
