import React from "react";
import { Typography } from "components/elements";
import { Colors } from "styles/theme/color";
import { Box, IconButton, Typography as Label } from "@mui/material";
import { Instagram, Phone, ShoppingBag } from "lucide-react";

const Footer = () => {
  const title =
    " © Supported By Ramworks | 2024 All Rights Reserved RUMAH BISNIS MANDIRI";
  return (
    <>
      <Box
        bgcolor={Colors.primaryBlue100}
        display="flex"
        justifyContent="center"
        paddingTop="32px"
        paddingBottom="24px"
        flexDirection="column"
        alignItems="center"
      >
        <Label
          fontSize={18}
          fontWeight={600}
          color={Colors.white}
          marginBottom="12px"
        >
          RUMAH BISNIS MANDIRI
        </Label>
        <Box display="flex" alignItems="center" gap="8px">
          <IconButton
            onClick={() =>
              window.open(
                "https://www.instagram.com/rbm.official.store",
                "_blank"
              )
            }
          >
            <Instagram color={Colors.white} size={22} />
          </IconButton>
          <IconButton
            onClick={() =>
              window.open("https://shopee.co.id/rbm.uns_", "_blank")
            }
          >
            <ShoppingBag color={Colors.white} size={22} />
          </IconButton>
          <IconButton
            onClick={() =>
              window.open(
                "https://wa.me/6287718654796?text=Hai Admin Rumah Bisnis Mandiri, saya ingin bertanya",
                "_blank"
              )
            }
          >
            <Phone color={Colors.white} size={22} />
          </IconButton>
        </Box>
      </Box>
      <Box
        bgcolor="#1f335b"
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding="12px"
      >
        <Typography type="body4" textAlign="center" color={Colors.white}>
          {title}
        </Typography>
      </Box>
    </>
  );
};

export default Footer;
