export const setLocalStorage = (key, value) => {
  if (typeof window !== 'undefined') localStorage.setItem(key, JSON.stringify(value));
};
export const getLocalStorage = (key) => {
  if (typeof window !== 'undefined') return localStorage.getItem(key);
};

export const setWithExpiry = (key, value, ttl) => {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };

  if (typeof window !== "undefined") {
    localStorage.setItem(key, JSON.stringify(item));
  }
};

export const getWithExpiry = (key) => {
  if (typeof window !== "undefined") {
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
    }
    return item;
  }
};

export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};
