import React from "react";
import Header from "components/layout/header";
import Footer from "components/layout/footer";

export default function Primary({ children }) {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
}
