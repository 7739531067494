import React from "react";
import { Box, Button, Grid } from "@mui/material";
import { Container, Typography } from "components/elements";
import { Colors } from "styles/theme/color";
import useResponsive from "utils/use-media-query";

const News = ({
  title = "Daftarkan produkmu sekarang, dan siap dapatkan pembeli setiamu!",
  padding = "0px 140px",
  label = "Hubungi Kami",
  handleClick = () =>
    window.open(
      "https://wa.me/6287718654796?text=Hai Rumah Bisnis Mandiri, saya ingin bergabung",
      "_blank"
    ),
}) => {
  const { laptop2, laptop, tablet } = useResponsive();

  if (laptop === null && laptop2 === null) return "";

  return (
    <Box backgroundColor={Colors.textColorPrimary}>
      <Container
        backgroundcolor={Colors.textColorPrimary}
        padding={laptop ? padding : "0px 16px"}
      >
        <Grid
          container
          alignItems="center"
          style={{ padding: laptop ? "84px 0px" : "48px 0px" }}
        >
          <Grid item sx={{ width: tablet ? "60%" : "100%" }}>
            <Typography
              type="heading2"
              fontSize={laptop ? "42px" : "22px"}
              color={Colors.white}
              width={laptop2 ? "659px" : "100%"}
            >
              {title}
            </Typography>
          </Grid>

          <Grid
            item
            sx={{
              width: tablet ? "40%" : "100%",
              marginTop: laptop ? "0px" : "12px",
            }}
          >
            <Box display="flex" justifyContent={laptop ? "center" : "start"}>
              <Button
                sx={{
                  background: Colors.textColorPrimary,
                  fontFamily: "Anek Bangla",
                  color: Colors.white,
                  textTransform: "none",
                  fontWeight: 700,
                  fontSize: laptop ? "24px" : "13px",
                  border: `1px solid ${Colors.white}`,
                  borderRadius: "30px",
                  padding: "9px 48px",
                  transition: "0.5 all ease",
                  "&:hover": {
                    border: `1px solid ${Colors.primaryOrange100}`,
                    color: Colors.primaryOrange100,
                  },
                }}
                onClick={handleClick}
              >
                {label}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default News;
