import React from "react";
import styled from "@emotion/styled";
import { Colors } from "styles/theme/color";
import { Container } from "@mui/material";

const Component = styled(Container)((props) => ({
  "&.MuiContainer-root": {
    padding: props.padding,
    paddingTop: props.paddingtop,
    paddingBottom: props.paddingbottom,
    height: props.height,
    borderRadius: props.borderradius,
  },
}));

const CustomContainer = ({
  children,
  fixed = true,
  maxWidth = "lg",
  ...props
}) => {
  return (
    <Component fixed={fixed} maxWidth={maxWidth} {...props}>
      {children}
    </Component>
  );
};

export default CustomContainer;
