export const required = (value) =>
  value || typeof value === "number" ? undefined : "";
export const password = (value) =>
  value &&
  !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*,.?]).{8,34}$/.test(
    value
  )
    ? "Harus berisi 8-34 huruf kecil dan kapital, angka dan juga simbol"
    : undefined;
export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Alamat email tidak valid"
    : undefined;

export const phoneNumber = (value) => {
  if (value && !/^[1-9][0-9]*$/i.test(value)) {
    return "Masukkan nomor handphone sesuai format (tanpa 0)";
  }
  if (value.length < 9) {
    return "Nomor Telepon Minimal 9 Karakter";
  }
  if (value.length > 13) {
    return "Nomor Telepon Maximal 13 Karakter";
  }

  return undefined;
};
export const accountNumber = (value, bankData) => {
  const rule = {
    "002": { min: 12, max: 18 },
    "008": { min: 11, max: 18 },
    "009": { min: 6, max: 12 },
    "013": { min: 6, max: 17 },
    "014": { min: 9, max: 11 },
  };

  if (bankData) {
    const bank = rule[bankData.code];
    const minDigit = bank ? bank.min : 6;
    const maxDigit = bank ? bank.max : 18;
    if (value.length < minDigit || value.length > maxDigit) {
      return "Masukkan nomor rekening yang sesuai";
    }
  }
  return undefined;
};
export const alphabetical = (value) =>
  value && !/^[a-zA-Z\s]*$/.test(value)
    ? "Masukkan karakter huruf saja"
    : undefined;
