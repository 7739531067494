import React, { useState } from "react";
import { Accordion, Container, Typography } from "components/elements";
import { Box } from "@mui/material";
import { Colors } from "styles/theme/color";
import useResponsive from "utils/use-media-query";

const FAQ = ({
  data = [
    {
      question: "Apa itu RUMAH BISNIS MANDIRI?",
      answer:
        "Rumah Bisnis Mandiri merupakan wadah yang dirancang sebagai pusat pelatihan, pendampingan, mentoring, konsultasi, dan pemasaran produk UMKM di Desa Ngringo.",
    },
    {
      question: "Produk apa saja yang ditawarkan RUMAH BISNIS MANDIRI?",
      answer:
        "Minuman Herbal, Pempek, Tongseng Genthong, Nasi Bowl, Juice & Capuccino, Intip Goreng, Peyek Mbah Uti, Es Durian Kocok, Tape Ketan, Pisang & Palawija, Rica-rica Menthok & Soto, Kacang Telur, Bakery, Keripik Kulit Kebab ",
    },
    {
      question: "Bagaimana cara pembelian di RUMAH BISNIS MANDIRI?",
      answer: "Melalui WhatsApp, Instagram, Shopee, Offline di Tempat ",
    },
    {
      question: "Apa keuntungan belanja di RUMAH BISNIS MANDIRI?",
      answer:
        "Harga terjangkau, Bahan terjamin kualitasnya, Menggunakan bahan alami & higienis, Produk segar & aman, Mendukung Perekonomian Lokal, Produk sudah bersertifikasi PIRT & Halal",
    },
  ],
  sliceEnd = 5,
}) => {
  const { laptop, tablet } = useResponsive();
  const [activeAccordion, setActiveAccordion] = useState();

  // handle on change accordion
  const handleAccordionChange = (index) => {
    if (activeAccordion !== index) setActiveAccordion(index);
    else setActiveAccordion(null);
  };

  return (
    <Box>
      <Container
        margin={laptop ? "0px 120px" : "0px"}
        padding={laptop ? "120px 80px" : "48px 24px"}
      >
        <Box display={laptop ? "flex" : "grid"} gap={laptop ? "63px" : "16px"}>
          <Typography
            type="heading2"
            fontSize={laptop ? "36px" : "22px"}
            width={tablet ? "445px" : "100%"}
          >
            Pertanyaan yang Sering Ditanyakan
          </Typography>
          <Box width={tablet ? "601px" : "100%"}>
            {data &&
              data.slice(0, sliceEnd).map((value, index) => (
                <Accordion
                  key={`question-${index}`}
                  id={`HomeFaQ${index + 1}`}
                  title={value.question}
                  detail={
                    <Typography type="body4" secondary>
                      <span
                        dangerouslySetInnerHTML={{ __html: value.answer }}
                      />
                    </Typography>
                  }
                  padding={index == 0 ? "0px 0px 24px" : "24px 0px"}
                  defaultExpanded={activeAccordion === index + 1}
                  onChange={() => handleAccordionChange(index + 1)}
                  TransitionProps={{ unmountOnExit: true }}
                  borderBottom={`1px solid ${Colors.darkGrey}`}
                  backgroundColor="transparent"
                  borderRadius="0px"
                  hideIcon
                />
              ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default FAQ;
