import React from "react";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import { Colors } from "styles/theme/color";

const CustomTypography = ({ type, children, secondary, id, ...props }) => {
  const Heading1 = styled(Typography)`
    color: ${secondary ? Colors.textColorSecondary : Colors.textPrimary};
    font-family: Anek Bangla;
    font-size: 42px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: normal;
    ${props}
  `;

  const Heading2 = styled(Typography)`
    color: ${secondary ? Colors.textColorSecondary : Colors.textPrimary};
    font-family: Anek Bangla;
    font-size: 36px;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: normal;
    ${props}
  `;

  const Heading3 = styled(Typography)`
    color: ${secondary ? Colors.textColorSecondary : Colors.textPrimary};
    font-family: Anek Bangla;
    font-size: 28px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: normal;
    ${props}
  `;

  const Heading4 = styled(Typography)`
    color: ${secondary ? Colors.textColorSecondary : Colors.textPrimary};
    font-family: Anek Bangla;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    ${props}
  `;

  const Heading5 = styled(Typography)`
    color: ${secondary ? Colors.textColorSecondary : Colors.textPrimary};
    font-family: Anek Bangla;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    ${props}
  `;

  const Heading6 = styled(Typography)`
    color: ${secondary ? Colors.textColorSecondary : Colors.textPrimary};
    font-family: Anek Bangla;
    font-size: 18px;
    font-weight: 700;
    line-height: 100%;
    ${props}
  `;

  const Heading7 = styled(Typography)`
    color: ${secondary ? Colors.textColorSecondary : Colors.textPrimary};
    font-family: Anek Bangla;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    ${props}
  `;

  const Button1 = styled(Typography)`
    color: ${secondary ? Colors.textColorSecondary : Colors.textPrimary};
    font-family: Anek Bangla;
    font-size: 24px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.48px;
    ${props}
  `;

  const Button2 = styled(Typography)`
    color: ${secondary ? Colors.textColorSecondary : Colors.textPrimary};
    font-family: Anek Bangla;
    font-size: 18px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.36px;
    ${props}
  `;

  const Button3 = styled(Typography)`
    color: ${secondary ? Colors.textColorSecondary : Colors.textPrimary};
    font-family: Anek Bangla;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    ${props}
  `;

  const Body1 = styled(Typography)`
    color: ${secondary ? Colors.textColorSecondary : Colors.textPrimary};
    font-family: Mulish;
    font-size: 22px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.374px;
    ${props}
  `;

  const Body2 = styled(Typography)`
    color: ${secondary ? Colors.textColorSecondary : Colors.textPrimary};
    font-family: Mulish;
    font-size: 18px;
    font-weight: 400;
    line-height: 100%;
    ${props}
  `;

  const Body3 = styled(Typography)`
    color: ${secondary ? Colors.textColorSecondary : Colors.textPrimary};
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.272px;
    ${props}
  `;

  const Body4 = styled(Typography)`
    color: ${secondary ? Colors.textColorSecondary : Colors.textPrimary};
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.238px;
    ${props}
  `;

  const Body5 = styled(Typography)`
    color: ${secondary ? Colors.textColorSecondary : Colors.textPrimary};
    font-family: Mulish;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.238px;
    ${props}
  `;

  const Caption = styled(Typography)`
    color: ${secondary ? Colors.textColorSecondary : Colors.textPrimary};
    font-family: Mulish;
    font-size: 10px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.17px;
    ${props}
  `;

  switch (type) {
    case "heading1":
      return (
        <Heading1 variant="h1" id={id}>
          {children}
        </Heading1>
      );
    case "heading2":
      return (
        <Heading2 variant="h2" id={id}>
          {children}
        </Heading2>
      );
    case "heading3":
      return (
        <Heading3 variant="h3" id={id}>
          {children}
        </Heading3>
      );
    case "heading4":
      return (
        <Heading4 variant="h4" id={id}>
          {children}
        </Heading4>
      );
    case "heading5":
      return (
        <Heading5 variant="h5" id={id}>
          {children}
        </Heading5>
      );
    case "heading6":
      return (
        <Heading6 variant="h6" id={id}>
          {children}
        </Heading6>
      );
    case "heading7":
      return (
        <Heading7 variant="h7" id={id}>
          {children}
        </Heading7>
      );
    case "button1":
      return (
        <Button1 variant="button" id={id}>
          {children}
        </Button1>
      );
    case "button2":
      return (
        <Button2 variant="button" id={id}>
          {children}
        </Button2>
      );
    case "button3":
      return (
        <Button3 variant="button" id={id}>
          {children}
        </Button3>
      );
    case "body1":
      return (
        <Body1 variant="body1" id={id}>
          {children}
        </Body1>
      );
    case "body2":
      return (
        <Body2 variant="body2" id={id}>
          {children}
        </Body2>
      );
    case "body3":
      return (
        <Body3 variant="subtitle1" id={id}>
          {children}
        </Body3>
      );
    case "body4":
      return (
        <Body4 variant="subtitle2" id={id}>
          {children}
        </Body4>
      );
    case "body5":
      return (
        <Body5 variant="subtitle2" id={id}>
          {children}
        </Body5>
      );
    case "caption":
      return (
        <Caption variant="caption" id={id}>
          {children}
        </Caption>
      );
  }
};

export default CustomTypography;
