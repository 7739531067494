import React, { useEffect, useState } from "react";
import axios from "axios";
import Logo from "assets/logo/logo.png";
import { Colors } from "styles/theme/color";
import { Button } from "components/elements";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import useResponsive from "utils/use-media-query";
import Loading from "components/layout/loading";
import { getLocalStorage, setLocalStorage } from "utils/local-storage";

const StyledInput = styled.input`
  margin-top: 8px;
  width: ${({ tablet }) => (tablet ? "426px" : "95%")};
  font-size: 14px;
  border-radius: 8px;
  padding: 12px;
  border: 1px solid ${Colors.darkGrey};
  outline: none;

  &:focus {
    border-width: 1px;
    border-color: ${Colors.blue100};
  }
`;

const LoginPage = () => {
  const { tablet } = useResponsive();
  const navigate = useNavigate();
  const isLogin = getLocalStorage("access_token");

  const [payload, setPayload] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Handle form input change
  const handleChange = (e) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      [e.target.name]: e.target.value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.post(
        "https://umkm-server.onrender.com/admin/signin",
        payload
      );

      setLocalStorage("access_token", data.access_token);
      setLoading(false);
      navigate("/backoffice-product");
    } catch (e) {
      setLoading(false);
      setError(e.response?.data?.message || "Error logging in");
    }
  };

  useEffect(() => {
    if (isLogin) navigate("/backoffice-product");
  }, []);

  if (loading) return <Loading />;

  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        display="grid"
        borderRadius="20px"
        padding="48px 32px"
        boxShadow={Colors.shadow}
      >
        <Box display="flex" alignItems="center" gap="8px">
          <img src={Logo} alt="logo" height="48px" />
          <Typography
            fontSize="23px"
            fontWeight={700}
            color={Colors.textColorPrimary}
          >
            RUMAH <span style={{ color: Colors.primaryBrown100 }}>BISNIS</span>{" "}
            <span style={{ color: Colors.primaryOrange100 }}>MANDIRI</span>
          </Typography>
        </Box>

        <Typography
          fontSize={tablet ? "32px" : "24px"}
          fontWeight={700}
          color={Colors.textColorPrimary}
          fontFamily="Anek Bangla"
          marginY="24px"
        >
          Masuk Sebagai Admin
        </Typography>

        <form onSubmit={handleSubmit}>
          <Typography color={Colors.textColorSecondary}>Email*</Typography>
          <StyledInput
            name="email"
            type="email"
            placeholder="rumahbisnis@gmail.com"
            value={payload.email}
            onChange={handleChange}
            tablet={tablet}
            required
          />

          <Typography marginTop="24px" color={Colors.textColorSecondary}>
            Password*
          </Typography>
          <StyledInput
            name="password"
            type="password"
            placeholder="•••••"
            value={payload.password}
            onChange={handleChange}
            tablet={tablet}
            required
          />

          <Box marginTop="48px" width="100%">
            <Button
              label="Login"
              height="42px"
              width="100%"
              padding="8px 24px"
              submit
            />
          </Box>
        </form>

        {error && (
          <Typography
            color="error"
            variant="body2"
            marginTop="16px"
            textAlign="center"
          >
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default LoginPage;
