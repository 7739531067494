import React, { useEffect, useState } from "react";
import { Box, Modal, Typography as Label } from "@mui/material";
import styled from "@emotion/styled";
import { Button, Typography } from "components/elements";
import { Colors } from "styles/theme/color";
import useResponsive from "utils/use-media-query";
import axios from "axios";
import { getLocalStorage } from "utils/local-storage";

const Component = styled(Box)(
  ({ width, padding }) => `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${width};
  background-color: ${Colors.white};
  box-shadow: 0px 10px 40px rgba(164, 149, 107, 0.1);
  padding: ${padding};
  border-radius: 25px;
  outline: none;
`
);

const UploadAssetsModal = ({ open, onClose, data }) => {
  const { tablet } = useResponsive();
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const access_token = getLocalStorage("access_token");

  const handleClose = () => {
    setFiles([]);
    setPreviews([]);
    onClose();
    setError(null);
  };

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);

    // Generate preview URLs for each file
    const previewUrls = selectedFiles.map((file) => URL.createObjectURL(file));
    setPreviews(previewUrls);
  };

  // Handle form submission with file upload
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!files.length) {
      setError("Tha Asets are Empty.");
      return;
    }

    if (files.length > 8) {
      setError("Gambar tidak boleh lebih dari 8");
      return;
    }

    const formData = new FormData();
    files.forEach((file, idx) => {
      formData.append(`assets`, file);
    });

    try {
      const response = await axios.post(
        `https://umkm-server.onrender.com/product/upload/${data.id}`,
        formData,
        {
          headers: {
            access_token: JSON.parse(access_token),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      handleClose();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e.response?.data?.message || "Error creating owner");
    }
  };

  useEffect(() => {
    return () => {
      previews.forEach((preview) => URL.revokeObjectURL(preview));
    };
  }, [previews]);

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-delete">
      <Component
        width={tablet ? "671px" : "95%"}
        padding={tablet ? "32px" : "16px"}
      >
        <Typography type="heading4" fontWeight={700} marginBottom="16px">
          {` Upload Assets - ${data.name}`}
        </Typography>
        <Typography type="body3" secondary marginBottom="12px">
          Blok lebih dari satu file untuk mengupload multiple gambar.
        </Typography>

        <Box display="flex" flexDirection="column">
          <Label variant="body1">Upload Assets*</Label>
          <input type="file" onChange={handleFileChange} multiple />{" "}
          {/* Allow multiple file selection */}
          <Box
            display="flex"
            gap="10px"
            marginTop="10px"
            overflow="scroll"
            marginBottom="24px"
          >
            {previews.map((preview, idx) => (
              <img
                key={idx}
                alt={`thumbnail-${idx}`}
                src={preview}
                height="100px"
                width="auto"
              />
            ))}
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-end" marginTop="24px">
          <Button
            type="secondary"
            label="Batal"
            padding="8px 16px"
            borderRadius="10px"
            margin="0px 16px 0px 0px"
            fontSize={tablet ? "18px" : "12px"}
            height="48px"
            onClick={handleClose}
          />
          <Button
            label={loading ? "Loading..." : "Upload"}
            padding="8px 16px"
            borderRadius="10px"
            fontSize={tablet ? "18px" : "12px"}
            height="48px"
            onClick={handleSubmit}
            disabled={loading}
          />
        </Box>
        {error && (
          <Label
            color="error"
            variant="body2"
            marginTop="16px"
            textAlign="center"
          >
            {error}
          </Label>
        )}
      </Component>
    </Modal>
  );
};

export default UploadAssetsModal;
