import React, { Fragment } from "react";
import Carousel from "react-multi-carousel";
import { v4 as uuidv4 } from "uuid";
import useResponsive from "utils/use-media-query";
import "react-multi-carousel/lib/styles.css";

const CarouselComponent = ({
  data,
  component,
  responsive,
  customButtonGroup,
}) => {
  const { mobile } = useResponsive();
  const Component = component;

  if (!data) return "";

  return (
    <Carousel
      length={data.length}
      itemAriaLabel="crousel"
      swipeable={true}
      draggable={true}
      showDots={false}
      arrows={false}
      responsive={responsive}
      rewind={true}
      autoPlay={mobile}
      autoPlaySpeed={3000}
      keyBoardControl={true}
      customTransition="transform 0.5s ease-in-out"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      dotListClass="custom-dot-list-style"
      justifyContent="center"
      customButtonGroup={customButtonGroup}
      focusOnSelect={false}
      partialVisible={true}
    >
      {data.map((value, index) => (
        <Fragment key={uuidv4()}>
          <Component data={value} isDraggable={false} index={index} />
        </Fragment>
      ))}
    </Carousel>
  );
};

export default CarouselComponent;
