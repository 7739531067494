import React from "react";
import { Box, Typography } from "@mui/material";
import { Headset } from "lucide-react";
import { Colors } from "styles/theme/color";
import useResponsive from "utils/use-media-query";

const ServiceButton = () => {
  const { tablet } = useResponsive();

  return (
    <Box
      zIndex={999}
      position="fixed"
      bottom="5%"
      display="flex"
      alignItems="center"
      right="5%"
      bgcolor={Colors.white}
      padding="9px 24px"
      borderRadius="25px"
      boxShadow={Colors.shadowSketch}
      sx={{
        border: `1px solid ${Colors.textColorPrimary}`,
        color: Colors.textPrimary,
        cursor: "pointer",
        transition: "0.5s all ease",
        "&:hover": {
          color: Colors.primaryOrange100,
          border: `1px solid ${Colors.primaryOrange100}`,
        },
      }}
      onClick={() =>
        window.open(
          "https://wa.me/6287718654796?text=Hai Admin Rumah Bisnis Mandiri, saya ingin bertanya",
          "_blank"
        )
      }
    >
      {" "}
      {tablet && (
        <Typography
          marginRight="7px"
          fontFamily="Anek Bangla"
          fontSize="20px"
          fontWeight={700}
        >
          Support
        </Typography>
      )}
      <Headset />
    </Box>
  );
};

export default ServiceButton;
