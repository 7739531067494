import React from "react";
import { Box, Modal, Typography as Label } from "@mui/material";
import styled from "@emotion/styled";
import { Button, Typography } from "components/elements";
import { Colors } from "styles/theme/color";
import useResponsive from "utils/use-media-query";

const Component = styled(Box)(
  ({ width, padding }) => `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${width};
  background-color: ${Colors.white};
  box-shadow: 0px 10px 40px rgba(164, 149, 107, 0.1);
  padding: ${padding};
  border-radius: 25px;
  outline: none;
`
);

const DeleteModal = ({ open, onClose, name, handleClick, loading, error }) => {
  const { tablet } = useResponsive();

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-delete">
      <Component
        width={tablet ? "451px" : "95%"}
        padding={tablet ? "32px" : "16px"}
      >
        <Typography type="heading3" fontWeight={700} marginBottom="16px">
          {`Apakah Anda yakin ingin Menghapus ${name}?`}
        </Typography>
        <Typography type="body3" secondary marginBottom="40px">
          Data yang related dengan item ini akan terhapus secara permanent.
        </Typography>

        <Box display="flex" justifyContent="flex-end">
          <Button
            type="secondary"
            label="Batalkan"
            padding="8px 16px"
            borderRadius="10px"
            margin="0px 16px 0px 0px"
            fontSize={tablet ? "18px" : "12px"}
            height="48px"
            onClick={onClose}
          />
          <Button
            label={loading ? "Loading..." : "Ya, Hapus"}
            padding="8px 16px"
            borderRadius="10px"
            fontSize={tablet ? "18px" : "12px"}
            height="48px"
            onClick={handleClick}
            disabled={loading}
          />
        </Box>
        {error && (
          <Label
            color="error"
            variant="body2"
            marginTop="16px"
            textAlign="center"
          >
            {error}
          </Label>
        )}
      </Component>
    </Modal>
  );
};

export default DeleteModal;
