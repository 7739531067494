const CurrencyFormatter = (amount, currency = "Rp") => {
  if (amount) {
    amount = `${currency} ${parseInt(amount)
      .toFixed(0)
      .replace(/(\d)(?=(\d{3})+\b)/g, "$1.")}`;
  }
  return amount;
};

export default CurrencyFormatter;
