import React from "react";
import { AppBar, Box, Typography } from "@mui/material";
import {
  Button,
  Container,
  Link,
  Typography as Label,
} from "components/elements";
import useResponsive from "utils/use-media-query";
import logo from "assets/logo/logo.png";
import { Colors } from "styles/theme/color";
import { Heart } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocalStorage, removeLocalStorage } from "utils/local-storage";

const Header = () => {
  const { tablet, laptop, laptop2 } = useResponsive();
  const isLogin = getLocalStorage("access_token");

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Container fixed maxWidth={false}>
      <AppBar
        color="inherit"
        position="fixed"
        sx={{
          boxShadow: Colors.shadowLight,
          padding: "12px 0px",
        }}
      >
        <Box
          display={tablet ? "flex" : "grid"}
          alignItems="center"
          justifyContent="space-between"
          padding={laptop ? "0px 32px" : "0px 16px"}
        >
          <Box
            display={tablet ? "flex" : "grid"}
            alignItems="center"
            // gap="100px"
          >
            <Box
              display="flex"
              alignItems="center"
              gap="8px"
              onClick={() => navigate("/")}
              sx={{ cursor: "pointer" }}
            >
              <img src={logo} alt="logo" height="48px" />
              <Typography
                fontSize="23px"
                fontWeight={700}
                color={Colors.textColorPrimary}
              >
                RUMAH{" "}
                <span style={{ color: Colors.primaryBrown100 }}>BISNIS</span>{" "}
                <span style={{ color: Colors.primaryOrange100 }}>MANDIRI</span>
              </Typography>
            </Box>

            <Box display="flex" alignItems="center">
              <Link href="/produk-umkm" target="_self">
                <Label
                  id="projectBtn"
                  type="body3"
                  cursor="pointer"
                  margin={laptop2 ? "0px 16px" : "0px 10px"}
                  color={Colors.textPrimary}
                >
                  UMKM
                </Label>
              </Link>
              <Link
                href={location.pathname !== "/" ? "/" : "#joinnow"}
                target="blank"
              >
                <Label
                  id="TopAjukanPendanaan"
                  type="body3"
                  margin={laptop2 ? "0px 16px" : "0px 10px"}
                  color={Colors.textPrimary}
                >
                  Daftarkan Produkmu
                </Label>
              </Link>
              {isLogin && (
                <>
                  <Link href="/backoffice-product" target="_self">
                    <Label
                      id="backoffice-product"
                      type="body3"
                      cursor="pointer"
                      margin={laptop2 ? "0px 16px" : "0px 10px"}
                      color={Colors.textPrimary}
                    >
                      PRODUK
                    </Label>
                  </Link>
                  <Link href="/backoffice-owner" target="_self">
                    <Label
                      id="backoffice-owner"
                      type="body3"
                      cursor="pointer"
                      margin={laptop2 ? "0px 16px" : "0px 10px"}
                      color={Colors.textPrimary}
                    >
                      PEMILIK USAHA
                    </Label>
                  </Link>
                </>
              )}

              {!tablet && isLogin && (
                <Button
                  label="Keluar"
                  height="38px"
                  padding="8px 24px"
                  type="secondary"
                  onClick={() => {
                    removeLocalStorage("access_token");
                    navigate("/");
                  }}
                />
              )}
            </Box>
          </Box>

          {/* Menus */}
          {tablet && (
            <Box display="flex" alignItems="center" gap="8px">
              <Box marginRight="8px">
                <Box>
                  <Box display="flex" alignItems="center" gap="4px">
                    <Heart size="14px" color={Colors.primaryBlue} />
                    <Label type="caption" color={Colors.primaryBlue}>
                      Lokal
                    </Label>
                  </Box>
                  <Box>
                    <Label
                      type="heading5"
                      color={Colors.primaryBlue}
                      lineHeight="80%"
                    >
                      100%
                    </Label>
                  </Box>
                </Box>
              </Box>

              {isLogin && (
                <Button
                  label="Keluar"
                  height="38px"
                  padding="8px 24px"
                  type="secondary"
                  onClick={() => {
                    removeLocalStorage("access_token");
                    navigate("/");
                  }}
                />
              )}
            </Box>
          )}
        </Box>
      </AppBar>
    </Container>
  );
};

export default Header;
