import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Dashboard from "pages/dashboard";
import NotFoundPage from "pages/not-found";
import Primary from "src/layouts/primary";
import Product from "pages/product";
import ProductDetail from "pages/product-detail";
import LoginPage from "pages/login";
import BackofficeProduct from "pages/backoffice/product";
import BackofficeOwer from "pages/backoffice/owner";
import OwnerCreation from "pages/backoffice/owner-create";
import ProductCreation from "pages/backoffice/product-create";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Primary children={<Dashboard />} />} />
      <Route path="/login" element={<LoginPage />} />

      <Route path="/produk-umkm" element={<Primary children={<Product />} />} />

      <Route
        path="/backoffice-product"
        element={<Primary children={<BackofficeProduct />} />}
      />
      <Route
        path="/backoffice-owner"
        element={<Primary children={<BackofficeOwer />} />}
      />
      <Route
        path="/backoffice-owner/create"
        element={<Primary children={<OwnerCreation />} />}
      />
      <Route
        path="/backoffice-product/create"
        element={<Primary children={<ProductCreation />} />}
      />

      <Route
        path="/produk-umkm/:id"
        element={<Primary children={<ProductDetail />} />}
      />
      <Route
        path="/backoffice-owner/edit/:id"
        element={<Primary children={<OwnerCreation />} />}
      />
      <Route
        path="/backoffice-product/edit/:id"
        element={<Primary children={<ProductCreation />} />}
      />

      <Route path="/404" element={<NotFoundPage />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export default AllRoutes;
