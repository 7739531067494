import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Pagination } from "components/elements";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Box,
  Skeleton,
  IconButton,
  TextField,
} from "@mui/material";
import { ImagePlus, PenLine, Plus, Search, Trash2, X } from "lucide-react";
import { Colors } from "styles/theme/color";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import useResponsive from "utils/use-media-query";
import CurrencyFormatter from "utils/currency-formatter";
import styled from "@emotion/styled";
import UploadAssetsModal from "components/pages/backoffice/upload-assets-modal";
import { getLocalStorage } from "utils/local-storage";
import DeleteModal from "components/pages/backoffice/delete-modal";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    padding: "0px",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
});

const BackofficeProduct = () => {
  const { laptop } = useResponsive();
  const navigate = useNavigate();
  const access_token = getLocalStorage("access_token");

  // Initialze State
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openUploadAssests, setOpenUploadAssests] = useState(false);
  const [currentProd, setCurrentProd] = useState({});
  const [openDeleteModal, setOpendeleteModal] = useState(false);
  const [dataLimit] = useState(10);

  const filters = [
    {
      background: Colors.gradientColorBlue,
      title: "Untuk Kamu",
      category: 0,
    },
    {
      background: Colors.gradientColorOrange,
      title: "Food & Snack",
      category: 1,
    },
    {
      background: Colors.gradientColorBrown,
      title: "Drink &  Ice",
      category: 2,
    },
    {
      background: Colors.gradientColorDiamond,
      title: "Furniture & Accessories",
      category: 3,
    },
  ];

  const listHead = [
    {
      id: 1,
      title: "nama",
      align: "left",
    },
    {
      id: 2,
      title: "harga",
      align: "left",
    },
    {
      id: 3,
      title: "berat",
      align: "left",
    },
    {
      id: 4,
      title: "stok",
      align: "left",
    },
    {
      id: 5,
      title: "pemilik",
      align: "left",
    },
    {
      id: 6,
      title: "kategori",
      align: "center",
    },
    {
      id: 7,
      title: "aksi",
      align: "center",
    },
  ];

  const getPaginatedData = (data) => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    const projectData = [...data];

    const final = projectData.slice(startIndex, endIndex);

    return final;
  };

  const getAllData = async () => {
    try {
      const { data } = await axios.get(
        "https://umkm-server.onrender.com/product/list"
      );
      setData(data);
    } catch (err) {
      setError(err);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `https://umkm-server.onrender.com/product/delete/${currentProd.id}`,
        {
          headers: { access_token: JSON.parse(access_token) },
        }
      );

      if (response.status == 200) {
        setLoading(false);
        setOpendeleteModal(false);
        getAllData();
      }
    } catch (e) {
      setLoading(false);
      setError(e.response?.data?.message || "Error logging in");
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    if (!access_token) navigate("/");
  }, []);

  const handleCate = (idx) => {
    return (
      <Box
        key={uuidv4()}
        borderRadius="7px"
        padding="4px 8px"
        display="flex"
        justifyContent="center"
        sx={{ background: filters[idx].background }}
      >
        <Typography fontSize="14px" color={Colors.white}>
          {filters[idx].title}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Container padding={laptop ? "100px 120px 64px" : "128px 24px 0px"}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box
            display="flex"
            alignItems="center"
            border={`1px solid ${Colors.black}`}
            borderRadius="27px"
            padding="0px 12px"
            height="48px"
            width="237px"
          >
            <Search />

            <StyledTextField
              placeholder="Keripik"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value.toLocaleLowerCase());
                setCurrentPage(1);
              }}
            />

            {search && (
              <IconButton onClick={() => setSearch("")}>
                <X size="16px" style={{ cursor: "pointer" }} />
              </IconButton>
            )}
          </Box>

          <Box
            padding="8px 18px"
            borderRadius="12px"
            sx={{ background: Colors.gradientColorBlue, cursor: "pointer" }}
            color={Colors.white}
            onClick={() => navigate("/backoffice-owner")}
          >
            PEMILIK ➤
          </Box>
        </Box>
        <Table sx={{ borderCollapse: "separate", borderSpacing: "0 21px" }}>
          <TableHead sx={{ position: "relative" }}>
            <TableRow>
              {listHead.map((item) => (
                <TableCell
                  align={item.align}
                  key={`${item.id}-heading`}
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: 550,
                    fontSize: 16,
                  }}
                >
                  {item.title}
                </TableCell>
              ))}
              <TableCell>
                <Box
                  data-testid="add"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="16px"
                  right="24px"
                  borderRadius="7px"
                  padding="3px"
                  sx={{
                    backgroundColor: Colors.primaryDarkBlue,
                    cursor: "pointer",
                    "&:hover": { opacity: 0.9 },
                  }}
                  onClick={() => navigate("/backoffice-product/create")}
                >
                  <Plus color={Colors.white} />
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!data ? (
              <>
                {[...Array(10)].map((_, id) => (
                  <TableRow
                    key={`${id}-TableRow`}
                    sx={{
                      cursor: "pointer",
                      backgroundColor: "white",
                      boxShadow: Colors.shadow,
                      transition: "0.5s all ease",
                      "&:hover": {
                        boxShadow: Colors.shadowSecond,
                      },
                      "& td, & th": {
                        border: 0,
                        overflow: "hidden",
                      },
                    }}
                  >
                    {[...Array(7)].map((_, idx) => (
                      <TableCell key={`${idx}-TableCell`}>
                        <Skeleton
                          data-testid="skeleton"
                          height="21px"
                          variant="rectangular"
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </>
            ) : data && data?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={listHead.length}>
                  <Typography margin="100px 0px" textAlign="center">
                    No data
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {getPaginatedData(
                  data?.filter((item) =>
                    search === ""
                      ? item
                      : item.name.toLowerCase().includes(search)
                  )
                )?.map((item) => (
                  <TableRow
                    key={uuidv4()}
                    sx={{
                      backgroundColor: "white",
                      transition: "0.5s all ease",
                      boxShadow: Colors.shadow,
                      "&:hover": {
                        boxShadow: Colors.shadowLightBlue,
                      },
                      "& td, & th": {
                        border: 0,
                        overflow: "hidden",
                      },
                    }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        borderTopLeftRadius: "9px",
                        borderBottomLeftRadius: "9px",
                      }}
                      width="300px"
                    >
                      <Box
                        display="flex"
                        gap={2}
                        alignItems="center"
                        sx={{ color: Colors.black, fontWeight: 500 }}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        <img
                          alt={item.name}
                          src={item.thumbnail}
                          style={{
                            height: 44,
                            width: 44,
                            borderRadius: 44,
                            objectFit: "cover",
                          }}
                        />
                        {item.name}
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      {item.price ? CurrencyFormatter(item.price) : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {item.weight ? item.weight + " g" : "-"}
                    </TableCell>
                    <TableCell align="left">{item.stock}</TableCell>
                    <TableCell align="left">{item.Owner?.owner}</TableCell>
                    <TableCell align="center">
                      {handleCate(item.category)}
                    </TableCell>
                    <TableCell align="right">
                      <Box display="flex" alignItems="center" gap="8px">
                        <IconButton
                          onClick={() =>
                            navigate("/backoffice-product/edit/" + item.id)
                          }
                        >
                          <PenLine color={Colors.primaryDarkBlue} />
                        </IconButton>

                        <IconButton
                          onClick={() => {
                            setOpendeleteModal(true);
                            setCurrentProd(item);
                            setError("");
                          }}
                        >
                          <Trash2 color={Colors.secondaryRed100} />
                        </IconButton>

                        <IconButton
                          onClick={() => {
                            setOpenUploadAssests(true);
                            setCurrentProd(item);
                          }}
                        >
                          <ImagePlus color={Colors.secondaryGreen} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>

        {/* Pagination */}
        {data && (
          <Box display="flex" justifyContent="center" marginTop="32px">
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageLimit={Math.ceil(
                data?.filter((item) =>
                  search === ""
                    ? item
                    : item.name.toLowerCase().includes(search)
                )?.length / dataLimit
              )}
            />
          </Box>
        )}
      </Container>

      {/* Add Assets */}
      <UploadAssetsModal
        open={openUploadAssests}
        onClose={() => setOpenUploadAssests(false)}
        data={currentProd}
      />

      {/* Modal */}
      <DeleteModal
        open={openDeleteModal}
        onClose={() => setOpendeleteModal(false)}
        name={currentProd.name || "-"}
        handleClick={handleDelete}
        loading={loading}
        error={error}
      />
    </>
  );
};

export default BackofficeProduct;
