import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "lucide-react";
import { Colors } from "styles/theme/color";
import { v4 as uuidv4 } from "uuid";
import styled from "@emotion/styled";

const Wrapper = styled(Box)(
  ({ background }) => `
    & .MuiIconButton-root {
      background-color: ${background};
      width: 40px;
      height: 40px;
      transition: 0.7s;
     
      &.Mui-disabled {
        background-color: ${background};
        opacity: 0.5;
      }
    }
  `
);

const Pagination = ({
  currentPage,
  setCurrentPage,
  pageLimit,
  customFunction,
  handlePrev,
  handleNext,
  handleToFirstPage,
  handleToEndPage,
  goToSlide,
}) => {
  const [paginationGroup, setPaginationGroup] = useState([]);
  const goToNextPage = useCallback(() => {
    if (customFunction) handleNext();
    else setCurrentPage((page) => page + 1);
  }, [customFunction, handleNext, setCurrentPage]);

  const goToPreviousPage = useCallback(() => {
    if (customFunction) handlePrev();
    else setCurrentPage((page) => page - 1);
  }, [customFunction, handlePrev, setCurrentPage]);

  const goToFirstPage = useCallback(() => {
    if (customFunction) handleToFirstPage();
    else setCurrentPage(1);
  }, [customFunction, handleToFirstPage, setCurrentPage]);

  const goToLastPage = useCallback(() => {
    if (customFunction) handleToEndPage();
    else setCurrentPage(pageLimit);
  }, [customFunction, handleToEndPage, setCurrentPage, pageLimit]);

  const handleClickSlide = useCallback(
    (item) => {
      if (customFunction) {
        const lastIndex = paginationGroup.length - 3;
        const thirdIndex = paginationGroup[2];

        if (item === "...") {
          goToSlide(paginationGroup[lastIndex]);
        } else if (item === " ...") {
          goToSlide(thirdIndex);
        } else if (item === "... ") {
          goToSlide(thirdIndex - 2);
        } else {
          goToSlide(item - 1);
        }
      } else {
        handleClickPage(item);
      }
    },
    [customFunction, goToSlide, paginationGroup]
  );

  const handleClickPage = useCallback(
    (value) => {
      if (typeof value === "string") {
        if (value === "...") {
          setCurrentPage(+paginationGroup[paginationGroup.length - 3] + 1);
        } else if (value === " ...") {
          setCurrentPage(+paginationGroup[2] + 1);
        } else if (value === "... ") {
          setCurrentPage(+paginationGroup[2] - 1);
        }
      } else {
        setCurrentPage(value);
      }
    },
    [paginationGroup, setCurrentPage]
  );

  useEffect(() => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    const value = new Array(pageLimit)
      .fill(null)
      .map((_, idx) => start + idx + 1);

    if (pageLimit < 6) {
      setPaginationGroup(value);
    } else if (currentPage >= 1 && currentPage <= 3) {
      setPaginationGroup([1, 2, 3, "...", pageLimit]);
    } else if (currentPage >= 4 && currentPage < pageLimit - 2) {
      setPaginationGroup([1, "... ", currentPage, " ...", pageLimit]);
    } else if (currentPage > pageLimit - 3) {
      const sliced = value.slice(pageLimit - 3);
      setPaginationGroup([1, "... ", ...sliced]);
    }
  }, [currentPage, pageLimit]);

  return (
    <Wrapper display="flex" alignItems="center" background="transparent">
      <ChevronsLeft
        size="26px"
        style={{ cursor: currentPage !== 1 ? "pointer" : "default" }}
        onClick={() => currentPage !== 1 && goToFirstPage()}
        color={
          currentPage !== 1
            ? Colors.textColorPrimary
            : Colors.textColorSecondary
        }
      />

      <ChevronLeft
        size="24px"
        onClick={() => currentPage !== 1 && goToPreviousPage()}
        style={{
          cursor: currentPage !== 1 ? "pointer" : "default",
          marginRight: "16px",
          marginLeft: "4px",
        }}
        color={
          currentPage !== 1
            ? Colors.textColorPrimary
            : Colors.textColorSecondary
        }
      />

      <Box display="flex" gap="4px" alignItems="center">
        {paginationGroup.map((num) => (
          <Typography
            key={uuidv4()}
            height="35px"
            width="35px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontSize="14px"
            fontWeight={500}
            color={currentPage === num ? Colors.white : Colors.grey100}
            onClick={() => handleClickSlide(num)}
            sx={{
              cursor: "pointer",
              borderRadius: "6.8px",
              border: `1px solid ${Colors.textColorPrimary}`,
              background:
                currentPage === num ? Colors.textColorPrimary : Colors.white,
              transition: "0.3s all ease",
              "&:hover": {
                border: `1px solid ${
                  currentPage === num
                    ? Colors.textColorPrimary
                    : Colors.primaryOrange100
                }`,
                color:
                  currentPage === num ? Colors.white : Colors.primaryOrange100,
              },
            }}
          >
            {num}
          </Typography>
        ))}
      </Box>

      <ChevronRight
        size="24px"
        onClick={() => currentPage !== pageLimit && goToNextPage()}
        style={{
          cursor: currentPage !== pageLimit ? "pointer" : "default",
          marginLeft: "16px",
          marginRight: "4px",
        }}
        color={
          currentPage !== pageLimit
            ? Colors.textColorPrimary
            : Colors.textColorSecondary
        }
      />

      <ChevronsRight
        size="24px"
        onClick={() => currentPage !== pageLimit && goToLastPage()}
        style={{ cursor: currentPage !== pageLimit ? "pointer" : "default" }}
        color={
          currentPage !== pageLimit
            ? Colors.textColorPrimary
            : Colors.textColorSecondary
        }
      />
    </Wrapper>
  );
};

export default Pagination;
